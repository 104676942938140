import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ActionStep } from '../models/action-step.model';
import { Action } from '../models/action.model';
import { ConnectorLog } from '../models/connector-log.model';
import { Connector } from '../models/connector.model';
import { Automate } from '../models/automateconnector.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class ActionApi extends ApiAbstraction<Action> {
  private readonly path = '/actions';

  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected definePath(): string {
    return '/actions';
  }

  public getStepsAction(id: string): Observable<ActionStep[]> {
    return this.httpClient.get<ActionStep[]>(`${this.path}/steps/${id}`);
  }

  public deleteStepAction(connectorUuid: string, actionUuid: string): Observable<unknown> {
    const step = {
      connectorid: connectorUuid,
      actionId: actionUuid,
      actionType: 'delete'
    };
    return this.httpClient.post<unknown>(`${this.path}/steps`, step);
  }

  public addStepAction(connectorUuid: string, actionUuid: string, targetAction: string): Observable<unknown> {
    const step = {
      targetAction,
      actionid: actionUuid,
      connectorid: connectorUuid,
      actionType: 'insert'
    };
    return this.httpClient.post<unknown>(`${this.path}/steps`, step);
  }

  public getAvailableConnectorsByAction(id: string): Observable<Connector[]> {
    return this.httpClient.get<Connector[]>(`${this.path}/availableconnectors/${id}`);
  }

  public getLogs(uuid: string): Observable<ConnectorLog[]> {
    return this.httpClient.get<ConnectorLog[]>(`${this.path}/${uuid}/logs`);
  }

  public getVenderInfo(uuid: string): Observable<any> {
    return this.httpClient.get<string>(`/hotels/venderInfo/${uuid}`);
  }

  public getAutomateById(uuid: string): Observable<any> {
    return this.httpClient.get<Automate[]>(`${this.path}/${uuid}`);
  }

  public getAllAutomate(): Observable<any> {
    return this.httpClient.get<string>(`/automate`);
  }

  public saveTrigger(actionUuid: string, triggerUuid: string): Observable<any> {
    var payload = {
      "actionId": actionUuid,
      "triggerId": triggerUuid
    }
    var url = '/automate/' + actionUuid + '/trigger';
    return this.httpClient.put<any>(url, payload);
  }
  public saveAction(actionUuid: string, actionStepUuid: string): Observable<any> {
    var payload = {
      "actionId": actionUuid,
      "actionStepUuid": actionStepUuid
    }
    var url = '/automate/' + actionUuid + '/action/';
    return this.httpClient.put<any>(url, payload);
  }

  public saveActionAndTriggers(body: any): Observable<any> {
    return this.httpClient.post<any>('/automate/action/triggers/properties', body);
  }

  public updateActionAndTriggers(body: any): Observable<any> {
    return this.httpClient.put<any>('/automate/action/triggers/properties/update', body);
  }

  public saveActionDescription(body: any): Observable<any> {
    return this.httpClient.put<any>('/automate/connectordetails', body);
  }
  public getConnectorData(connectorid: any): Observable<any> {
    return this.httpClient.get<any>(`/automate/connectordetails/${connectorid}`);
  }

  public getactionandtriggerssettings(
    sourceconnectorid,
    targetconnectorid,
    marketplaceactiontriggerid,
    istrigger
  ): Observable<any> {
    return this.httpClient.get<any>(
      `/automate/actiontriggers/${istrigger}/properties/${marketplaceactiontriggerid}?sourceconnectorid=${sourceconnectorid}&&targetconnectorid=${targetconnectorid}`
    );
  }

  public getConnectorIds(actionUuid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/connector/${actionUuid}`);
  }
  public getSystemList(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/systemlist`);
  }

  public createAction(body): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.path}`, body);
  }

  public updateAction(body): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}`, body);
  }

  public updateActionConnectorId(body): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/connector`, body);
  }

  public updateTrigger(body): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/actiontrigger/update`, body);
  }
  public updateActionDetails(body): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/actiondetails/update`, body);
  }
}
