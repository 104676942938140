import { ChangeDetectionStrategy, Component, forwardRef, Input } from '@angular/core';
import { ControlValueAccessor, NG_VALUE_ACCESSOR } from '@angular/forms';

@Component({
  selector: 'hcon-input',
  templateUrl: './input-control.component.html',
  styleUrls: ['./input-control.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => InputControlComponent),
      multi: true
    }
  ]
})
export class InputControlComponent implements ControlValueAccessor {
  private touched: () => void;
  private changed: (value: string) => void;

  @Input() public type = 'text';
  @Input() public readonly = false;
  @Input() public placeholder = '';
  @Input() public invalid = false;

  public value: string;

  public registerOnChange(changed: (value: string) => void): void {
    this.changed = changed;
  }

  public registerOnTouched(touched: () => void): void {
    this.touched = touched;
  }

  public writeValue(value: string): void {
    this.value = value;
  }

  public updateValue(value: string): void {
    this.value = value;
    this.changed(value);
  }
}
