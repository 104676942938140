import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { NewSignupRequest, NewSignupSuccess } from '../models/register.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class RegistrationApi extends ApiAbstraction<NewSignupRequest> {

  private readonly path = '/register';

  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected definePath(): string {
    return `/register`;
  }


  public signupUser(company: string, name: string, email: string): Observable<unknown> {
    var signupDetails = {
      company: company,
      name: name,
      email: email
    };
    return this.httpClient.post<unknown>(`${this.path}`, signupDetails);
  }

  public getVenderInfo(uuid: string): Observable<any> {
    return this.httpClient.get<string>(`/hotels/venderInfo/${uuid}`)
  }

  public registerAPICall(dataObject: object): Observable<any[]> {

    return this.httpClient.post<any>(`/auth/register/`, dataObject);

  }

}