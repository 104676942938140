import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { ApiAbstraction } from './api.abstraction';
import { SettingConnector } from '../models/setting-connector.model';

@Injectable()
export class OperaCloudSyncApi extends ApiAbstraction<any> {
  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }
  private readonly path = '/operacloudsync';

  protected definePath(): string {
    return '/operacloudsync';
  }

  public getProjectList(): Observable<any[]> {

    return this.httpClient.get<any[]>(`${this.path}/getlist`);
  }

  public createNewProject(data): Observable<any[]> {

    return this.httpClient.post<any[]>(`${this.path}/createproject`, data);
  }
  public getSyncProjectDetails(syncuuid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/details/${syncuuid}`);
  }
  public getTask(syncprojectid): Observable<any[]> {

    return this.httpClient.get<any[]>(`${this.path}/task/${syncprojectid}`);
  }
  public getSettingConnector(uuid: string, sourcetarget): Observable<SettingConnector[]> {
    return this.httpClient.get<SettingConnector[]>(`${this.path}/${uuid}/connectorsettings/${sourcetarget}`);
  }
  public updateTasks(body, syncuuid, taskId): Observable<any> {

    return this.httpClient.put<any>(`${this.path}/${syncuuid}/task/${taskId}`, body);
  }
  public updateSyncProjectDetails(syncuuid, body): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/details/${syncuuid}`, body);
  }
}
