import { ChangeDetectionStrategy, ChangeDetectorRef, Component, Input } from '@angular/core';

import { Menu } from '../models/menu.model';

@Component({ 
  selector: 'hcon-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class SidebarComponent {
  @Input() public isCollapsed: boolean;
  @Input() public menu: Menu[];

  public constructor(private changeDetectorRef: ChangeDetectorRef) {}

  public toggleMenu(menuItemIndex: number): void {
    this.menu.forEach((menuItem, index) => {
      if (index !== menuItemIndex) {
        menuItem.isOpenedSub = false;
      } else {
        menuItem.isOpenedSub = !menuItem.isOpenedSub;
      }
    }); 
    this.changeDetectorRef.detectChanges();
  }
}
