import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class LoadingService {
  private readonly isShowLoading$ = new BehaviorSubject<any>(false);

  private _timer: any;
  lodingString:any;

  get loadingStatus$(): Observable<any> {
    return this.isShowLoading$.asObservable();
  }

  public show(data): void {
    // let data  = 'Saving'
    this.isShowLoading$.next(data);
   
  }

  public hide(): void {
    if (this._timer) {
      clearTimeout(this._timer);
    }
    this._timer = setTimeout(() => this.isShowLoading$.next(false), 300);
  }
}
