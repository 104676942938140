import { ChangeDetectionStrategy, Component, Input, OnInit, Output } from '@angular/core';
import { BehaviorSubject, combineLatest, Observable, of, ReplaySubject } from 'rxjs';
import { shareReplay, switchMap } from 'rxjs/operators';

import { Pagination } from './pagination.model';

@Component({
  selector: 'hcon-pagination',
  templateUrl: './pagination.component.html',
  styleUrls: ['./pagination.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PaginationComponent implements OnInit {
  private readonly defaultPageSize = 10;
  private readonly pageSize$ = new BehaviorSubject<number>(this.defaultPageSize);
  private readonly page$ = new BehaviorSubject<number>(1);
  private readonly total$ = new ReplaySubject<number>(1);

  private _totalPages: number;

  public pagination$: Observable<Pagination[]>;
  public selectedPage: number;

  get currentPage(): number {
    return this.page$.value;
  }

  @Input() set page(value: number) {
    this.page$.next(value || 1);
  }

  @Input() set pageSize(value: number) {
    this.pageSize$.next(value || this.defaultPageSize);
  }

  @Input() set total(value: number) {
    this.total$.next(value);
  }

  @Output() public pageChange = this.page$.asObservable();

  public ngOnInit(): void {
    this.pagination$ = combineLatest([this.pageSize$, this.total$, this.page$]).pipe(
      switchMap(([pageSize, total, page]) => of(this.generateIndicators(pageSize, page, total))),
      shareReplay(1)
    );
  }

  public changeSelectedPage(pageNumber: number): void {
    if (pageNumber !== this.currentPage) {
      this.page$.next(pageNumber);
    }
  }

  private generateIndicators(pageSize: number, page: number, total = 0): Pagination[] {
    this._totalPages = Math.ceil(total / pageSize);
    if (this._totalPages < 2) {
      return [];
    } else if (this._totalPages < 8) {
      return new Array(this._totalPages)
        .fill(undefined, 0, this._totalPages)
        .map((_, index) => ({ type: 'page', page: index + 1 }));
    } else {
      const distanceToHideMore = 3;
      const selectedPage = page;
      const hasLeft = selectedPage >= 1 + distanceToHideMore;
      const hasRight = selectedPage <= this._totalPages - distanceToHideMore;
      const left = hasLeft ? [{ type: selectedPage - 1 === distanceToHideMore ? 'page' : 'more', page: 2 }] : [];
      const right = hasRight
        ? [
            {
              type: this._totalPages - selectedPage === distanceToHideMore ? 'page' : 'more',
              page: this._totalPages - 1
            }
          ]
        : [];
      const center = [selectedPage - 1, selectedPage, selectedPage + 1]
        .filter(it => it > 1 && it < this._totalPages)
        .map(it => ({ type: 'page', page: it }));

      return [
        { type: 'page', page: 1 },
        ...left,
        ...center,
        ...right,
        { type: 'page', page: this._totalPages }
      ] as Pagination[];
    }
  }
}
