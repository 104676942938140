import { Component } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { HeaderStore } from '@stores/header.store';
import { SidebarStore } from '@stores/sidebar.store';

@Component({ template: '' })
export class PrivateBaseComponent {

  public readonly menu$ = this.sidebarStore.menu$;

  get userName(): String {
    const user = this.authService.getUserFirst();
    return user;
    
  }

  protected constructor(
    protected authService: AuthService, 
    protected sidebarStore: SidebarStore,
    protected headerStore: HeaderStore
  ) {
  }

  public logout(): void {
    
    this.headerStore.logout();
  }
  
}
