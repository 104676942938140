import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

export abstract class ApiAbstraction<T> {
  private readonly basePath: string;

  protected constructor(protected httpClient: HttpClient) {
    this.basePath = this.definePath();
  }

  protected abstract definePath(): string;

  public create(item: Partial<T>): Observable<string> {
    return this.httpClient.post<string>(this.basePath, [item]);
  }

  public update(item: Partial<T>, itemId?: string): Observable<unknown> {
    const url = itemId ? `${this.basePath}/${itemId}` : this.basePath;
    return this.httpClient.put<unknown>(url, [item]);
  }

  public getAll(): Observable<T[]> {
    return this.httpClient.get<T[]>(this.basePath);
  }

  public getOne(id: string): Observable<T> {
    return this.httpClient.get<T[]>(`${this.basePath}/${id}`).pipe(map(response => response[0]));
  }

  public delete(id: string): Observable<string> {
    return this.httpClient.delete<string>(`${this.basePath}/${id}`);
  }
}
