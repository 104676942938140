import { AfterViewInit, ChangeDetectionStrategy, Component, ElementRef, EventEmitter, Output, ViewChild } from '@angular/core';
import { adjustTableButtonStyle } from './utils';

declare const $: any;

@Component({
  selector: 'hcon-j-table',
  templateUrl: './j-table.component.html',
  styleUrls: ['./j-table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class JTableComponent implements AfterViewInit {
  @ViewChild('jTable', { static: true }) private jTable: ElementRef<HTMLTableElement>;
  @ViewChild('buttons', { static: true }) private buttons: ElementRef<HTMLDivElement>;

  @Output() public refresh = new EventEmitter<unknown>();

  public ngAfterViewInit(): void {
    this.renderTable();
  }
  constructor() { }
  private renderTable(): void {

    $($(this.jTable.nativeElement).find('thead tr').get(0))
      .clone(true)
      .addClass('filters')
      .appendTo($(this.jTable.nativeElement).find('thead').get(0));

    const table = $(this.jTable.nativeElement).DataTable({
      destroy: true,
      responsive: true,
      pageLength: 50,
      order: [[0, 'desc']],
      dom: 'Bfrtip',
      paging:true,
      buttons: {
        dom: {
          button: {
            tag: 'button'
          }
        },
        buttons: [
          {
            extend: 'csv',
            text: 'CSV',
            init: (_, node, config) => adjustTableButtonStyle(node, config)
          },
          {
            extend: 'excel',
            text: 'Excel',
            init: (_, node, config) => adjustTableButtonStyle(node, config)
          },
          {
            extend: 'print',
            text: 'Print',
            init: (_, node, config) => adjustTableButtonStyle(node, config)
          },
          {
            extend: 'pdf',
            text: 'PDF',
            orientation: 'landscape',
            pageSize: 'A4',
            init: (_, node, config) => adjustTableButtonStyle(node, config)
          },
          {
            extend: 'copy',
            text: 'Copy',
            init: (_, node, config) => adjustTableButtonStyle(node, config)
          },
          {
            text: 'Refresh',
            action: () => this.refresh.emit(),
            init: (_, node, config) => adjustTableButtonStyle(node, config)
          }
        ]
      },
      language: {
        emptyTable: `No messages or transactions logged for this connector.  <a href='javascript:void(0)' role='button' (click)='getLogs()'>Refresh</a>`
      },
      orderCellsTop: true,
      fixedHeader: true,
      initComplete: function () {
        var api = this.api();

        // For each column
        api
          .columns()
          .eq(0)
          .each(function (colIdx) {
            // Set the header cell to contain the input element
            var cell = $('.filters th').eq(
              $(api.column(colIdx).header()).index()
            );
            var title = $(cell).text();
            $(cell).html('<input type="text" placeholder="' + title + '" />');

            // On every keypress in this input
            $(
              'input',
              $('.filters th').eq($(api.column(colIdx).header()).index())
            )
              .off('keyup change')
              .on('keyup change', function (e) {
                e.stopPropagation();

                // Get the search value
                $(this).attr('title', $(this).val());
                var regexr = '({search})'; //$(this).parents('th').find('select').val();

                var cursorPosition = this.selectionStart;
                // Search the column for that value
                api
                  .column(colIdx)
                  .search(
                    this.value != ''
                      ? regexr.replace('{search}', '(((' + this.value + ')))')
                      : '',
                    this.value != '',
                    this.value == ''
                  )
                  .draw();

                $(this)
                  .focus()[0]
                  .setSelectionRange(cursorPosition, cursorPosition);
              });
          });
      }
    });

    $($(this.jTable.nativeElement)).css({ width: '100%', height: '100%', overflow: 'hidden' })

    table.buttons().container().appendTo(this.buttons.nativeElement);
  }


}
