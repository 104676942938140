import { HttpEvent, HttpHandler, HttpHeaders, HttpInterceptor, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { ENDPOINT_PREFIX } from '@environments/environment';
import { from, Observable } from 'rxjs';
import { every, switchMap } from 'rxjs/operators';
import { Router } from '@angular/router';

import { AuthService } from '../services/auth.service';

@Injectable()
export class HeaderInterceptor implements HttpInterceptor {

  public constructor(private authService: AuthService, private router: Router,) { }
  public intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    var token = true;
    if (request.url.includes('/account/users/') && request.url.includes('/password')) { token = false };
    if (request.url.includes('/auth/register/')) {
      token = false
    }
    if (request.url.includes('/auth/login')) {
      token = false
    }
    if (request.url.includes('/auth/forgetpassword')) {
      token = false
    }
    if (request.url.includes('/auth/forgetpassword')) {
      token = false
    }
    if (request.url.includes('/auth/logout')) {
      token = false
    }
    if (request.url.includes('/auth/setpassword')) {
      token = false
    }
    if (request.url.includes('/auth/confirmregistration')) {
      token = false
    }
   

    if (!token) {
      let url = request.url;
      url = `${ENDPOINT_PREFIX}${url}`;
      let headers = new HttpHeaders();
      return next.handle(request.clone({ url, headers }));
    }
    else {

      return from(this.getJwtToken()).pipe(
        switchMap(token => {

          let url = request.url;
          if (url.charCodeAt(0) === 47 && !url.startsWith('/assets')) {

            url = `${ENDPOINT_PREFIX}${url}`;

          }
          let headers = new HttpHeaders({ Authorization: token });

          return next.handle(request.clone({ url, headers }));

        })
      );
    }

  }

  private getJwtToken(): Promise<any> {
    if (localStorage.length == 0 || sessionStorage.length == 0) {

      return this.router.navigateByUrl('/login')
    }
    return new Promise((resolve, reject) => {
      let isAuthenticate = this.authService.getAuthenticatedUser();

      resolve(isAuthenticate)

    }

    );

  }

}