import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { ConnectorUniversalApi } from '../models/connector-universal-api.model';
import { ResourceUniversalApi } from '../models/resource-universal-api.model';
import { UniversalApi as ShapeUniversalApi } from '../models/universal-api.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class UniversalApi extends ApiAbstraction<ShapeUniversalApi> {
  private readonly path = '/universalapi';

  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected definePath(): string {
    return '/universalapi';
  }

  public getResourcesById(apiId: string): Observable<ResourceUniversalApi[]> {
    return this.httpClient.get<ResourceUniversalApi[]>(`${this.path}/${apiId}/resources`);
  }

  public getConnectorsById(apiId: string): Observable<ConnectorUniversalApi[]> {
    return this.httpClient.get<ConnectorUniversalApi[]>(`${this.path}/${apiId}/connectors`);
  }

  public universalApiListMethod(): Observable<any[]> {
    let universalapiuuid = 'list';
    return this.httpClient.get<any[]>(`${this.path}/list/${universalapiuuid}`);
  }
  public deleteResoucePath(data): Observable<any[]> {
    
    return this.httpClient.delete<any[]>(`${this.path}/deleteresourcepath/${data.universalapiid}/${data.resourcePath}`);
  }
  public addResoucePath(data): Observable<any[]> {
    // let universalapiuuid = 'delete';
    return this.httpClient.post<any[]>(`${this.path}/addpath`,data);
  }

  public getUniversalResource(universalapiuuid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/list/${universalapiuuid}`);
  }

  public addConnectorToApi(apiId: string, connectorId: number, resourcePath: string): Observable<unknown> {
    const connector = {
      apiid: apiId,
      connectorid: connectorId,
      resourcepath: resourcePath
    };
    return this.httpClient.post<unknown>(`${this.path}/${apiId}/resources`, connector);
  }
  public addUniversalApi(body): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.path}/insert`,body);
  }
  
}
