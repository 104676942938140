import { Injectable } from '@angular/core';
import { noop } from 'rxjs';
import { Router } from '@angular/router';
import { AuthService } from '@core/services/auth.service';
import { Keepalive } from '@ng-idle/keepalive';
import {AppComponent} from './app.component'

@Injectable({
  providedIn: 'root'
})
export class SessiontimeoutService {
  private timeoutId: number;
  constructor(private router: Router, private authService: AuthService,) { }
  // count:number = 1
  startSessionTimeout(minutes: number): void {
    const milliseconds = minutes * 60 * 1000;   // wait for 10 minute
    // const milliseconds = 10;
    this.timeoutId = setTimeout(() => this.logout(), milliseconds);
  }
  resetSessionTimeout(): void {
    clearTimeout(this.timeoutId);
    this.startSessionTimeout(10);
  }
  logout(): void {
    // let username = localStorage.getItem("userName");
    // this.keepalive.onPing.unsubscribe();
    let username = sessionStorage.getItem("userName");

    this.authService.logout(username);
    sessionStorage.clear();
    localStorage.clear();
    this.router.navigateByUrl('/login').then(noop);
  }
}
