import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Connector } from '../models/connector-management.model';
import { ApiAbstraction } from './api.abstraction';
import { Queue } from '../models/queue.model';
import { ConnectorLog } from '../models/connector-log.model';

@Injectable()
export class ConnectorManagementApi extends ApiAbstraction<Connector> {
  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }
  private path = '/admin/connector';

  protected definePath(): string {
    return '/admin/connector';
  }
  public getDynamoDBLogs(uuid: string): Observable<ConnectorLog[]> {
    return this.httpClient.get<ConnectorLog[]>(`${this.path}/${uuid}/logs`);
  }

  public getQueueCount(uuid: string): Observable<Queue> {

    return this.httpClient.get<Queue>(`${this.path}/${uuid}/queue`);
  }

  public updateSettings(uuid: string, payload: any): Observable<unknown> {
    return this.httpClient.post<unknown>(`${this.path}/${uuid}`,payload);
  }

}
