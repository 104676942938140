import { CommonModule } from '@angular/common';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { LoadingIndicatorComponent } from './loading-indicator.component';
import { LoadingService } from './loading.service';
import { SpinnerComponent } from './spinner.component';

@NgModule({
  declarations: [LoadingIndicatorComponent, SpinnerComponent],
  imports: [CommonModule],
  exports: [LoadingIndicatorComponent],
  providers: [LoadingService]
})
export class LoadingIndicatorModule {
  public constructor(@Optional() @SkipSelf() private parentModule: LoadingIndicatorModule) {
    if (parentModule) {
      throw new Error('LoadingIndicatorModule is already loaded. Import it in the AppModule only');
    }
  }
}
