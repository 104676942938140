import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProcessRename } from '../models/process-rename.model';
import { ProfileInfo } from '../models/profile-info.model';
import { StepProcess } from '../models/step-process.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class ProfileApi extends ApiAbstraction<ProfileInfo> {
  private readonly path = '/profile';

  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected definePath(): string {
    return '/profile';
  }

  public updateGdbrNda(body): Observable<string> {
      
    return this.httpClient.post<string>(`${this.path}/policy`, body);
  }

  public updateProfile(data:any):Observable<any>{
    return this.httpClient.put<any>(`${this.path}`,data);
  }

  public getCountryListData(): Observable<string> {
    return this.httpClient.get<string>(`${this.path}/countrylist`);
  }

}
