import { AccountRole } from '@core/models/account-role.model';
import { ProfileInfo } from '@core/models/profile-info.model';
import { Menu } from '@hconnect-ui';


import { ADMIN_MENU, END_USER_MENU } from '../constants/sidebar.constant';
import { MenuPermission } from '../models/menu-permission.model';

function mapMenuConditions(profile: ProfileInfo): MenuPermission {
  return profile && profile.modulePermission
    ? {
      isShowConnectors: profile.modulePermission.isShowConnectors,
      isShowUniversalApi: profile.modulePermission.isShowUniversalApi,
      isShowEsb: profile.modulePermission.isShowEsb,
      isShowSync: profile.modulePermission.isShowSync,
      isShowVendor: profile.role === AccountRole.VENDOR,
      isVaultOnly: profile.modulePermission.isVaultOnly,
      isSubAccounts: profile.modulePermission.isSubAccounts
    }
    : ({} as MenuPermission);
}

function adjustAdminMenu(): Menu[] {
  return ADMIN_MENU;
}

function adjustEndUserMenu(profile: ProfileInfo): Menu[] {
  const menuConditions = mapMenuConditions(profile);
  let role = profile.role
  let modoperasync = profile.modoperasync
  let modVault = profile.modvault
  let moduniversalapi = profile.moduniversalapi
  
  // let modVault = 'N'
  END_USER_MENU.forEach(item => {
    if (item.name == "VAULT") {
      if (role != "HOTEL") {
        item.isDisplayed = false;
      } else {
        if(modVault != 'Y' && modVault != undefined){
          item.isDisplayed = false;
        }else{
          item.isDisplayed = true;
        }
        
      }
    } 
    if (item.name == "OPERA CLOUD SYNC") {
      if (modoperasync == "Y" && role == "HOTEL") {
        item.isDisplayed = false; // for temporary false this
      } else {
        item.isDisplayed = false;
      }

    }
    if(item.name == "UNIVERSAL API"){
      if(role == "HOTEL" && moduniversalapi == "Y"){
        item.isDisplayed = true;
      } else {
       
        item.isDisplayed = false;
      }
    }

    if (item.submenu != undefined && item.submenu.length > 0) {
      item.submenu.forEach(subItem => {

        subItem.isDisplayed = true
        if (subItem.name == "Billing") {
          if (role == "HOTEL") {
            subItem.isDisplayed = true
          } else {
            subItem.isDisplayed = false
          }

        }
        if (subItem.name == "Settings") {
          if (role == "VENDOR") {
            subItem.isDisplayed = false
          } else {
            subItem.isDisplayed = true
          }
        }

        if (item.name == "SETTINGS" && (subItem.name == "Region Settings" || subItem.name == "Connections" || subItem.name == "Vendor Access")) {
          if (role == "HOTEL") {
            subItem.isDisplayed = true
          } else {
            subItem.isDisplayed = false
          }
        }
        if (subItem.name == "Linked Accounts") {
          if (role == "HOTEL" &&  menuConditions.isSubAccounts ) {
            subItem.isDisplayed = true
          } else {
            subItem.isDisplayed = false
          }
        }
      })   
    }
  })
 
  if (menuConditions.isVaultOnly === true) {
    END_USER_MENU.forEach(item => {
      if (item.name === 'SYNC') {
        item.isDisplayed = menuConditions.isShowSync;
        if (item.submenu != undefined && item.submenu.length > 0) {
          item.submenu.forEach(subItem => {
            if (subItem.name !== 'Vault') {
              subItem.isDisplayed = false
            }
          });
        }
      } else {
        item.isDisplayed = false
      }
    });

  } else {
    END_USER_MENU.forEach(item => {
      if (item.name === 'AUTOMATE') {
        item.isDisplayed = menuConditions.isShowConnectors;
      }
      if (item.name === 'ESB') {
        item.isDisplayed = menuConditions.isShowEsb;
        // item.submenu.find(sub => sub.name === 'Universal API').isDisplayed = menuConditions.isShowUniversalApi;
      }
      if (item.name === 'SYNC') {
        item.isDisplayed = menuConditions.isShowSync;
      }
      if (item.name === 'UNIVERSAL API') {
        // item.isDisplayed = menuConditions.isShowUniversalApi;
      }
      if (item.name === 'Vendor Settings') {
        item.isDisplayed = menuConditions.isShowVendor;
      }
    });
  }
  return END_USER_MENU;
}


export function adjustSidebarMenu(profile: ProfileInfo): Menu[] {
  
  switch (profile.role) {
    case AccountRole.ADMIN:
      return adjustAdminMenu();
    case AccountRole.HOTEL:
    case AccountRole.VENDOR:
      return adjustEndUserMenu(profile);
  }
}
