import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SyncProject } from '../models/sync-project.model';
import { SourceConnectorSystem } from '../models/sync-source-connector.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class SyncApi extends ApiAbstraction<SyncProject> {
  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }
  private readonly path = '/sync';

  protected definePath(): string {
    return '/sync';
  }

  public updateProcessStatus(syncScopeId: number, isRunning: string): Observable<any[]> {
    
    return this.httpClient.put<any[]>(`${this.path}/status/update`, { syncScopeId, isRunning: isRunning });
  }

  public getConnectorData(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/connector`);
  }

  public communicationMethod(syncuuid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/communication/${syncuuid}`);
  }
  public scopeSummeryMethod(syncuuid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/scopesummary/${syncuuid}`);
  }

  public getSyncHelpText(connectoruuid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/synchelptext/${connectoruuid}`);
  }

  public reconcileMethod(params, syncId, offsetValue, itemsPerPage): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/scopesummary/sync/${syncId}/reconcile/${offsetValue}/itemperpage/${itemsPerPage}`, { params });
  }

  public changeRecordStatus(data, status): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/scopesummary/reconcile/recordstatus`, { data, status });
  }

  public getScopeData(syncProjectUuid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/scope/${syncProjectUuid}`);
  }

  public getSummaryData(syncuuid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/summary/${syncuuid}`);
  }

  public getSourceSystemData(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/sourcesystem`);
  }

  public getTargetSystemData(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/targetsystem`);
  }

  public createConnector(body): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.path}/createconnector`, body);
  }

  public createProject(body): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.path}/createproject`, body);
  }

  public updateScope(body): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/updatescope`, body);
  }

  public updateTargetTestConnection(connectorId, body): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/testconnection/${connectorId}`, body);
  }

  public connectorDeploy(projectId): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/deployaws/${projectId}`, { });
  }

  public updateProject(projectId): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/checkconnectorstatus/${projectId}`);
  }

  public getSourceConnector(connectoruuid: string): Observable<SourceConnectorSystem[]> {
    return this.httpClient.get<SourceConnectorSystem[]>(`${this.path}/source/${connectoruuid}`);
  }

  public updateWizardStep(body): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/wizardstep`, body);
  }

  public updateCommunicationStatus(sourceConnectorId, targetConnectorId, projectId): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/updateconnection/sourceId/${sourceConnectorId}/targetId/${targetConnectorId}`, { projectId: projectId });
  }
  public conversionMethod(syncuuid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/conversion/${syncuuid}`);
  }
  public searchConversion(syncuuid,params): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/conversion/${syncuuid}`,{params});
  }
  public updateConversion(body): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/conversion/syncmap`, body);
  }
  public projectDetails(params): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}`, { params });
  }
  public updateSettings(body): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/updatesettings`, body);
  }
  public addScopeFilter(body): Observable<any[]> {
    return this.httpClient.post<any[]>(`${this.path}/updatescope`, body);
  }

}
