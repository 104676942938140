import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule, Optional, SkipSelf } from '@angular/core';

import { AccountManagementApi } from './api/account-management.api';
import { VendorManagementApi } from './api/vendor-management.api';
import { ConnectorManagementApi } from './api/connector-management.api';
import { SystemLibraryApi } from './api/system-library.api';
import { ActionApi } from './api/action.api';
import { ApplicationApi } from './api/application.api';
import { ConnectorApi } from './api/connector.api';
import { CustomerApi } from './api/customer.api';
import { EsbApi } from './api/esb.api';
import { ProfileApi } from './api/profile.api';
import { LibraryApi } from './api/library.api';
import { SyncApi } from './api/sync.api';
import { VaultApi } from './api/vault.api';
import { SyncTemplateApi } from './api/sync-templates.api';
import { UniversalApi } from './api/universal.api';
import { UserApi } from './api/user.api';
import { HeaderInterceptor } from './interceptors/header.interceptor';
import { AuthService } from './services/auth.service';
import { AdminGuard } from './guards/admin.guard';
import { AuthGuard } from './guards/auth.guard';
import { RegistrationApi } from './api/registration.api';
import { BillingApi } from './api/billing.api';
import { BillingDetailApi } from './api/billing-detail.api';
import { OperaCloudSyncApi } from './api/operacloudsync.api';
import { AuthServicesApi } from './api/authservices.api';

@NgModule({
  imports: [HttpClientModule],
  providers: [
    AccountManagementApi,
    VendorManagementApi,
    ConnectorManagementApi,
    ActionApi,
    VaultApi,
    SyncTemplateApi,
    ApplicationApi,
    AuthService,
    ConnectorApi,
    CustomerApi,
    EsbApi,
    ProfileApi,
    LibraryApi,
    SyncApi,
    OperaCloudSyncApi,
    SystemLibraryApi,
    UniversalApi,
    UserApi,
    RegistrationApi,
    AuthGuard,
    AdminGuard,
    BillingApi,
    BillingDetailApi,
    AuthServicesApi,
    { provide: HTTP_INTERCEPTORS, useClass: HeaderInterceptor, multi: true }
  ]
})
export class CoreModule {
  public constructor(@Optional() @SkipSelf() parentModule: CoreModule) {
    if (parentModule) {
      throw new Error('CoreModule is already loaded. Import it in the AppModule only');
    }
  }
}
