import { AfterViewInit, Component, ElementRef, Input, ViewChild } from '@angular/core';

declare const $: any;

@Component({
  selector: 'app-data-table',
  templateUrl: './data-table.component.html',
  styleUrls: ['./data-table.component.sass']
})
export class DataTableComponent implements AfterViewInit {
  @ViewChild('dataTable') private dataTable: ElementRef<HTMLTableElement>;
  @Input() searching: boolean = true;
  @Input() ordering: boolean = true;
  @Input() destroy: boolean = true;
  @Input()responsive: boolean = true;
  @Input() pageLength: any = 25;
  @Input() bLengthChange: boolean = true;
  @Input() bPaginate: boolean = true;
  
  public constructor() {}

  public ngAfterViewInit(): void {
    $(this.dataTable.nativeElement).DataTable({
      searching: this.searching,
      ordering:  this.ordering,
      destroy: this.destroy,
      responsive: this.responsive,
      pageLength: this.pageLength,
      bLengthChange: this.bLengthChange,
      bPaginate: this.bPaginate
    });
  }
}
