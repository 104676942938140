import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { LibraryList } from '../models/system-library.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class SystemLibraryApi extends ApiAbstraction<LibraryList> {
  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }
  private readonly path = '/admin/library';

  protected definePath(): string {
    return '/admin/library';
  }

  /*
  public updateSettings(account: Account): Observable<unknown> {
  
    return this.httpClient.put<unknown>(`${this.path}/settings`, account);
  }
*/
  

}
