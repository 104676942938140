import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Account } from '../models/account.model';
import { User } from '../models/user.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class UserApi extends ApiAbstraction<User> {
  private readonly path = '/account';

  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected definePath(): string {
    return `/account/users`;
  }

  public getAccounts(): Observable<Account[]> {
    
    return this.httpClient.get<Account[]>(this.path);
  } 

  public deactivateUser(userId:string,status):Observable<any>{
    return this.httpClient.post(this.path+`/users/${userId}/deactivate`,{status:status});
  }
  public setPassword(userId: string, password: string, code: string): Observable<any> {
    var postData  = {
      password: password,
      code: code
    };
    return this.httpClient.put(this.path + `/users/${userId}/password`, postData);
  }
}