export const environment = {
  production: false
};
export const CLIENT_ID = '4rp10j9gb10td0rkn7ijn6a0p';
export const USER_POOL_ID = 'ap-southeast-2_L4hkCXn2r';
// export const ENDPOINT_PREFIX = 'https://5rsx0psoq6.execute-api.ap-southeast-2.amazonaws.com/v1';
export const ENDPOINT_PREFIX = 'https://c7bte72tjf.execute-api.ap-southeast-2.amazonaws.com/v1';
export const URL_STATUS_PAGE = 'https://hconnect.statuspage.io/';
export const UI_ENV = 'Test';
export const PRIVACY_URL = 'https://www.iubenda.com/privacy-policy/53654941';
export const TERMS_URL = 'https://www.iubenda.com/terms-and-conditions/53654941';
export const IMAGE_URL = 'https://hconnectdev-images.s3.ap-southeast-2.amazonaws.com/';
export const GRAPHQL_URL = 'https://bys3l2d4enerjnkklkd5mzw5ry.appsync-api.ap-southeast-2.amazonaws.com/graphql';
// export const VENDER_APPLICATION_LOGO = 'https://5rsx0psoq6.execute-api.ap-southeast-2.amazonaws.com/v1/vendor/applications/logo';
export const VENDER_APPLICATION_LOGO = 'https://c7bte72tjf.execute-api.ap-southeast-2.amazonaws.com/v1/vendor/applications/logo';
export const WEB_URL = 'http://hconnectdev.s3-website-ap-southeast-2.amazonaws.com';

//v99 done