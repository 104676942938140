import { Observable, Subject } from 'rxjs';

import { Sort } from './sort.model';

export class TableService {
  private readonly sort$ = new Subject<Sort>();

  public setSort(sort: Sort): void {
    this.sort$.next(sort);
  }

  public getSort(): Observable<Sort> {
    return this.sort$.asObservable();
  }
}
