import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { AdminGuard } from '@core/guards/admin.guard';
import { AuthGuard } from '@core/guards/auth.guard';
import { NotFoundComponent } from '@pages/static/not-found/not-found.component';

const routes: Routes = [
  
  { path: 'actions', canActivate: [AuthGuard], loadChildren: () => import('@modules/action/action.module').then(m => m.ActionModule) },
  { path: 'billing', canActivate: [AuthGuard], loadChildren: () => import('@modules/billing/billing.module').then(m => m.BillingModule) },
  { path: 'billing/detail/:id', canActivate: [AuthGuard], loadChildren: () => import('@modules/billing-detail/billing-detail.module').then(m => m.BillingDetailModule) },
  { path: 'change-password', canActivate: [AuthGuard], loadChildren: () => import('@pages/change-password/change-password.module').then(m => m.ChangePasswordModule) },
  { path: 'connectors', canActivate: [AuthGuard], loadChildren: () => import('@modules/automate-connector/automate-connector.module').then(m => m.AutomateConnectorModule) },
  { path: 'automate', canActivate: [AuthGuard], loadChildren: () => import('@modules/automate-connector/automate-connector.module').then(m => m.AutomateConnectorModule) },
  { path: 'dashboard', canActivate: [AuthGuard], loadChildren: () => import('@modules/dashboard/dashboard.module').then(m => m.DashboardModule) },
  { path: 'esb', canActivate: [AuthGuard], loadChildren: () => import('@modules/esb/esb.module').then(m => m.EsbModule) },
  { path: 'forgot-password', loadChildren: () => import('@pages/forgot-password/forgot-password.module').then(m => m.ForgotPasswordModule) },
  { path: 'library', canActivate: [AuthGuard], loadChildren: () => import('@modules/library/library.module').then(m => m.LibraryModule) },
  { path: 'login', loadChildren: () => import('@pages/login/login.module').then(m => m.LoginModule) },
  { path: 'partner', loadChildren: () => import('@pages/partnerregistration/partner.module').then(m => m.PartnerModule) },
  { path: 'profile', canActivate: [AuthGuard], loadChildren: () => import('@modules/profile/profile.module').then(m => m.ProfileModule) },
  { path: 'register', loadChildren: () => import('@pages/register/register.module').then(m => m.RegisterModule) },
  { path: 'verify', loadChildren: () => import('@pages/verify/verify.module').then(m => m.VerifyModule) },
  { path: 'registrationcomplete', loadChildren: () => import('@pages/registrationcomplete/registrationcomplete.module').then(m => m.RegistrationCompleteModule) },
  { path: 'set-password', loadChildren: () => import('@pages/set-password/set-password.module').then(m => m.SetPasswordModule) },
  { path: 'regionsettings', canActivate: [AuthGuard], loadChildren: () => import('@modules/account-setting/account-setting.module').then(m => m.AccountSettingModule) },
  { path: 'sync', canActivate: [AuthGuard], loadChildren: () => import('@modules/sync/sync.module').then(m => m.SyncModule) },
  { path: 'vault', canActivate: [AuthGuard], loadChildren: () => import('@modules/vault/vault.module').then(m => m.VaultModule) },
  { path: 'systems', canActivate: [AuthGuard], loadChildren: () => import('@modules/connectors/connectors.module').then(m => m.ConnectorsModule) },
  { path: 'universalapi', canActivate: [AuthGuard], loadChildren: () => import('@modules/universal-api/universal-api.module').then(m => m.UniversalApiModule) },
  { path: 'users', canActivate: [AuthGuard], loadChildren: () => import('@modules/user/user.module').then(m => m.UserModule) },
  { path: 'vendor/customers', canActivate: [AuthGuard], loadChildren: () => import('@modules/vendor-customer/vendor-customer.module').then(m => m.VendorCustomerModule) },
  { path: 'vendor/applications', canActivate: [AuthGuard], loadChildren: () => import('@modules/vendor-application/vendor-application.module').then(m => m.VendorApplicationModule) },
  { path: 'linked-accounts', canActivate: [AuthGuard], loadChildren: () => import('@modules/linked-accounts/linked-accounts.module').then(m => m.linkedAccountsModule) },
  
  
  { path: 'vendors', canActivate: [AuthGuard], loadChildren: () => import('@modules/vendors/vendors.module').then(m => m.vendorsModule) },
  { path: 'operasync', canActivate: [AuthGuard], loadChildren: () => import('@modules/operacloudsync/operacloudsync.module').then(m => m.OperacloudsyncModule) }, 
  { path: '', redirectTo: 'dashboard', pathMatch: 'full' },
  { path: '**', redirectTo: 'dashboard' },
  { path: 'dashboard',canActivate: [AuthGuard], loadChildren: () => import('./pages/static/static.module').then(m => m.StaticModule)  }
  // { path: 'not-found', component: NotFoundComponent }
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }