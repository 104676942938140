import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { AuthLayoutModule } from '@shared/auth-layout/auth-layout.module';

import { NotFoundComponent } from './not-found/not-found.component';

@NgModule({
  declarations: [NotFoundComponent],
  imports: [CommonModule, AuthLayoutModule],
  exports: [NotFoundComponent]
})
export class StaticModule {}
