import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { LayoutModule } from '@hconnect-ui';

import { PrivateLayoutComponent } from './private-layout/private-layout.component';
import { PublicLayoutComponent } from './public-layout/public-layout.component';

@NgModule({
  declarations: [PrivateLayoutComponent, PublicLayoutComponent],
  imports: [CommonModule, LayoutModule],
  exports: [PrivateLayoutComponent, PublicLayoutComponent]
})
export class AuthLayoutModule {}
