import { ChangeDetectionStrategy, Component } from '@angular/core';
import { AuthService } from '@core/services/auth.service';
import { PrivateBaseComponent } from '@shared/abstracts/private-base.component';
import { HeaderStore } from '@stores/header.store';
import { SidebarStore } from '@stores/sidebar.store';
import { Router } from '@angular/router';

@Component({
  templateUrl: './not-found.component.html',
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class NotFoundComponent extends PrivateBaseComponent {
  public constructor(
    protected authService: AuthService,
    protected sidebarStore: SidebarStore,
    protected headerStore: HeaderStore,
    private router: Router,
  ) {
    super(authService, sidebarStore, headerStore);
    this.router.navigateByUrl('/dashboard')
  }
}
