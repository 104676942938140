import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingDetailList } from '../models/billing-detail.model';

// import { VaultRecordList, VaultList } from '../models/vault.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class BillingDetailApi extends ApiAbstraction<BillingDetailList> {
  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected definePath(): string {
    return '/billing';
  }

  public getBillingDetailList(stripeSubscriptionID): Observable<BillingDetailList[]> {

    var path = '/billing/subscriptions/details/' + stripeSubscriptionID;
    return this.httpClient.get<BillingDetailList[]>(path);
    // var path = 'https://5rsx0psoq6.execute-api.ap-southeast-2.amazonaws.com/v1/billing/createsubscription'
    // return this.httpClient.get<BillingList[]>(path);
  }
}
