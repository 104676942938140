import { ChangeDetectionStrategy, Component, Output } from '@angular/core';

import { TableService } from '../table.service';

@Component({
  selector: 'hcon-table',
  templateUrl: './table.component.html',
  styleUrls: ['./table.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush,
  providers: [TableService]
})
export class TableComponent {
  @Output() public readonly sortChanged = this.tableService.getSort();

  public constructor(private tableService: TableService) {}
}
