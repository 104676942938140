import { HashLocationStrategy, LocationStrategy, PathLocationStrategy } from '@angular/common';
import { HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatDatepickerModule } from '@angular/material/datepicker';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import { MatStepperModule } from '@angular/material/stepper';
import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { CoreModule } from '@core/core.module';
import { LoadingIndicatorModule } from '@hconnect-ui';
import { FormlyBootstrapModule } from '@ngx-formly/bootstrap';
import { FormlyModule } from '@ngx-formly/core';
import { StaticModule } from '@pages/static/static.module';
import { HeaderStore } from '@stores/header.store';
import { SidebarStore } from '@stores/sidebar.store';
import { ArchwizardModule } from 'angular-archwizard';
import { NgToggleModule } from 'ng-toggle-button';
import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { DataTableModule } from './shared/data-table/data-table.module';
import { NgIdleKeepaliveModule } from '@ng-idle/keepalive';
import { SessiontimeoutService } from '../app/sessiontimeout.service'
import { FormlyMaterialModule } from "@ngx-formly/material";
import { NgxPaginationModule } from 'ngx-pagination';
// import { DataTablesModule } from "angular-datatables";

@NgModule({
  declarations: [AppComponent],
  imports: [
    BrowserModule,
    AppRoutingModule,
    NgToggleModule,
    FormsModule,
    HttpClientModule,
    MatFormFieldModule,
    MatStepperModule,
    MatDatepickerModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatExpansionModule,
    MatInputModule,
    ArchwizardModule,
    FormlyModule.forRoot({
      validationMessages: [{ name: 'required', message: 'This field is required' }]
    }),
    FormlyBootstrapModule,
    CoreModule,
    LoadingIndicatorModule,
    StaticModule,
    DataTableModule,
    NgIdleKeepaliveModule.forRoot(),
    // DataTablesModule
    // FormlyMaterialModule
    NgxPaginationModule,
  ],
  providers: [SidebarStore, SessiontimeoutService, HeaderStore, { provide: LocationStrategy, useClass: PathLocationStrategy }],
  bootstrap: [AppComponent]
})
export class AppModule { }
