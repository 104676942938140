import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';
import { environment } from '@environments/environment';

import { AppModule } from './app/app.module';

if (environment.production) {
  enableProdMode();
}
else
{
  console.log('NON PRODUCTION ENVIRONMENT');
}

platformBrowserDynamic()
  .bootstrapModule(AppModule)
  .catch(error => {
    if (!environment.production) {
      console.error('Error occurred:', error);
    }
  });
