import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AuthServiceRequest } from '../models/authResqest.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class AuthServicesApi extends ApiAbstraction<AuthServiceRequest> {

    private readonly path = '/auth';

    public constructor(protected httpClient: HttpClient) {
        super(httpClient);
    }

    protected definePath(): string {
        return '/auth';
    }

    // public forgetPasswordAPICall(dataObject: object): Observable<any[]> {
    //     return this.httpClient.post<any>(`${this.path}/forgetpassword`, dataObject);
    // }

    public forgetPasswordAPICall(dataObject: object): Observable<any[]> {

        const authServiceAPi = this.httpClient.post<any>(`${this.path}/forgetpassword`, dataObject);

        return authServiceAPi

    }

    public signInAPICall(dataObject: object): Observable<any[]> {

        const authServiceAPi = this.httpClient.post<any>(`${this.path}/login`, dataObject);
        return authServiceAPi

    }

    public chnagePasswordAPICall(dataObject: object): Observable<any> {

        const authServiceAPi = this.httpClient.post<any>(`${this.path}/changepassword`, dataObject);
        return authServiceAPi
    }

    public setPasswordAPICall(dataObject: object): Observable<any> {

        return this.httpClient.post<any>(`${this.path}/setpassword`, dataObject);
    }

    public registrationConfirmationAPICall(dataObject: object): Observable<any> {

        return this.httpClient.post<any>(`${this.path}/confirmregistration`, dataObject);
    }

    public logoutApiCall(dataObject: object): Observable<any> {

        return this.httpClient.post<any>(`${this.path}/logout`, dataObject);
    }

}