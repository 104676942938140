import { ChangeDetectionStrategy, Component, OnInit } from '@angular/core';

import { LoadingService } from './loading.service';

@Component({
  selector: 'hcon-loading-indicator',
  templateUrl: './loading-indicator.component.html',
  styleUrls: ['./loading-indicator.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class LoadingIndicatorComponent{
  public readonly isShowLoading$ = this.loadingService.loadingStatus$;

  // tempString:any;
  public constructor(private loadingService: LoadingService) {
    // this.tempString = this.loadingService.show(' ');
  }
}
