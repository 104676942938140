import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { TableBodyComponent } from './table-body/table-body.component';
import { TableCellComponent } from './table-cell/table-cell.component';
import { TableHeaderComponent } from './table-header/table-header.component';
import { TableHeadersComponent } from './table-headers/table-headers.component';
import { TableRowComponent } from './table-row/table-row.component';
import { TableComponent } from './table/table.component';

@NgModule({
  declarations: [
    TableComponent,
    TableCellComponent,
    TableRowComponent,
    TableHeadersComponent,
    TableHeaderComponent,
    TableBodyComponent
  ],
  imports: [CommonModule],
  exports: [
    TableComponent,
    TableCellComponent,
    TableRowComponent,
    TableHeadersComponent,
    TableHeaderComponent,
    TableBodyComponent
  ]
})
export class TableModule {}
