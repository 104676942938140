import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { AttachConnectorAction } from '../models/attach-connector-action.model';
import { ConnectorAction } from '../models/connector-action.model';
import { ConnectorLog } from '../models/connector-log.model';
import { ConnectorTrigger } from '../models/connector-trigger.model';
import { Connector } from '../models/connector.model';
import { Queue } from '../models/queue.model';
import { SettingConnector } from '../models/setting-connector.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class ConnectorApi extends ApiAbstraction<Connector> {
  private readonly path = '/connectors';

  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected definePath(): string {
    return '/connectors';
  }

  public getConnectorActions(): Observable<ConnectorAction[]> {
    return this.httpClient.get<ConnectorAction[]>(`${this.path}/targetactions`);
  }

  public getConnectorTriggers(): Observable<ConnectorTrigger[]> {
    return this.httpClient.get<ConnectorTrigger[]>(`${this.path}/triggers`);
  }

  public getSettingConnector(uuid: string): Observable<SettingConnector[]> {
    return this.httpClient.get<SettingConnector[]>(`${this.path}/${uuid}/settings`);
  }

  public getActionsByConnector(uuid: string): Observable<AttachConnectorAction[]> {
    return this.httpClient.get<AttachConnectorAction[]>(`${this.path}/${uuid}/actions`);
  }

  // public getDynamoDBLogs(uuid: string): Observable<ConnectorLog[]> {
  //   return this.httpClient.get<ConnectorLog[]>(`${this.path}/${uuid}/logs`);
  // }
  public getDynamoDBLogs(variables): Observable<ConnectorLog[]> {
    return this.httpClient.get<ConnectorLog[]>(`${this.path}/dynamo/logs`, { params: variables });
  }

  public createConnectorDB(uuid: string): Observable<unknown> {
    return this.httpClient.patch<unknown>(`${this.path}/${uuid}`, undefined);
  }

  public testConnector(uuid: string): Observable<unknown> {
    return this.httpClient.put<unknown>(`${this.path}/${uuid}`, undefined);
  }
  public deleteConnector(connectorid: string): Observable<unknown> {
    return this.httpClient.put<unknown>(`${this.path}/system/${connectorid}`, undefined);
  }

  public getQueueCount(uuid: string): Observable<Queue> {
    return this.httpClient.get<Queue>(`${this.path}/${uuid}/queue`);
  }


  public getConnectorApplications(uuid: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/${uuid}/customers`);
  }

  public getActionsAndTriggers(sourceid: string, targetid: string): Observable<any> {
    return this.httpClient.get<any>(`${this.path}/action/${sourceid}/trigger/${targetid}`);
  }

  public getactiontriggersproperties(istrigger, id): Observable<any> {
    return this.httpClient.get<any>(`${this.path}/properties/${istrigger}/marketplace/${id}`);
  }

  public getconnectorsBySystemId(systemid): Observable<any> {
    return this.httpClient.get<any>(`${this.path}/systemconnector/${systemid}`);
  }

  public getmarketplacesettings(connectoruuid, systemid, isnewconnector): Observable<any> {
    return this.httpClient.get<any>(
      `${this.path}/marketplace/settings/${systemid}?connectoruuid=${connectoruuid}&&isnewconnector=${isnewconnector}`
    );
  }

  public updatetriggeranddetailsconnectorid(connectorid, body): Observable<any> {
    return this.httpClient.put<any>(`${this.path}/actiontriggerdetails/${connectorid}`, body);
  }

  public doesApiKeyExist(apikey: string): Observable<any> {
    return this.httpClient.get<any>(`${this.path}/apikey/${apikey}`);
  }

  public delpoyVersion(connectoruuid: string): Observable<any> {
    return this.httpClient.put<any>(`${this.path}/updateversion/update/${connectoruuid}`, '');
  }

  public checkRecordInMarketPlaceVersionTable(connectoruuid: string): Observable<any> {
    return this.httpClient.get<any>(`${this.path}/updateversion/${connectoruuid}`);
  }

  public manualResyncData(data): Observable<any> {
    return this.httpClient.put<any>(`${this.path}/manualresync`,data);
  }

}
