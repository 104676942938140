import { ChangeDetectionStrategy, Component, Input, OnInit } from '@angular/core';

import { TableService } from '../table.service';

@Component({
  selector: 'hcon-table-header',
  templateUrl: './table-header.component.html',
  styleUrls: ['./table-header.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class TableHeaderComponent implements OnInit {
  @Input() public sortKey: string;
  public isAscending = true;
  public isOnSort = false;

  public constructor(private tableService: TableService) {}

  public ngOnInit(): void {
    this.tableService.getSort().subscribe(sort => (this.isOnSort = this.sortKey === sort.sortKey));
  }

  public onSort(): void {
    this.isAscending = !this.isAscending;
    this.tableService.setSort({ sortKey: this.sortKey, isAscending: this.isAscending });
  }
}
