import { Injectable } from '@angular/core';
import { CLIENT_ID, USER_POOL_ID } from '@environments/environment';
import { AuthenticationDetails, CognitoUser, CognitoUserPool, CognitoUserAttribute } from 'amazon-cognito-identity-js';
import { BehaviorSubject, Observable } from 'rxjs';
import { filter, map, shareReplay } from 'rxjs/operators';
import { UserApi } from '../api/user.api';
import { ProfileInfo } from '../models/profile-info.model';
import { NewSignupSuccess } from '../models/register.model';
// import { RegisterService } from '../../pages/register/register.service';
import { RegistrationApi } from "../api/registration.api";
import { AuthServicesApi } from "../api/authservices.api";
import { Router } from '@angular/router';
import { noop } from 'rxjs';


type AuthStatus = 'success' | 'reset';
interface VerifyStatus { success: string, statusMessage: string };
interface SignUpStatus { success: string, statusMessage: string };
type ResetAuthStatus = 'success' | 'confirm';
interface ResetAuth {
  type: ResetAuthStatus;
  cognitoUser?: CognitoUser;
}

@Injectable()
export class AuthService {

 
  private readonly isSignIn$ = new BehaviorSubject<boolean>(false);
  private readonly userPool: CognitoUserPool;

  private _profileDetails$: Observable<ProfileInfo>;
  private signUpResponse: Observable<NewSignupSuccess>;

  get profileDetails$(): Observable<ProfileInfo> {
    if (!this._profileDetails$) {

      this._profileDetails$ = this.userApi.getAccounts().pipe(
        filter(accounts => !!accounts && !!accounts.length),
        map(accounts => accounts[0]),
        map(account => (
          {
            userName: account.username,
            firstName: account.firstname,
            lastName: account.lastname,
            fullName: account.contactname,
            role: account.accounttype,
            id: account.accountid,
            useruuId: account.useruuid,
            accountuuId: account.accountuuid,
            iubendId: account.iubendId,
            identifier: account.identifier,
            modoperasync: account.modoperasync,
            moduniversalapi:account.moduniversalapi,
            modvault: account.modvault,
            rolename: account.rolename,
            modulePermission: {
              isVaultOnly: account.rolename === 'VAULTONLY',
              isShowConnectors: account.modconnectors === 'Y',
              isShowEsb: account.modesb === 'Y',
              isShowSync: account.modsync === 'Y',
              isShowUniversalApi: account.moduniversalapi === 'Y',
              isSubAccounts : account.allowsubaccounts === 'Y'
            },
            dashboardItems: {
              confirmednda: account.confirmednda,
              confirmedgdpr: account.confirmedgdpr,
              applicationCount: account.applicationCount,
              deployments: account.deployments,
              customers: account.customers,
              profileComplete: account.profileComplete,
              billingComplete: account.billingconfirmed
            },
            billingmethod: account.billingmethod,
            billingDetails: {
              companyname: account.companyname,
              billingcontact: account.billingcontact,
              billingaddress1: account.billingaddress1,
              billingaddress2: account.billingaddress2,
              billingaddress3: account.billingaddress3,
              billingcountry: account.billingcountry,
              billingemail: account.billingemail,
              billingphone: account.billingphone,
              vatno: account.vatno,
            },
            contactDetails: {
              contactname: account.contactname,
              contactemail: account.contactemail
            }

          }

        )),
        shareReplay(1)
      );
      // this._profileDetails$.subscribe((data)=>{
      //   localStorage.setItem('role',data.role)
      // })
    }
    return this._profileDetails$;
  }

  get signInStatus$(): Observable<boolean> {
    return this.isSignIn$.asObservable();
  }

  public constructor(
    private userApi: UserApi,
    private registerAPI: RegistrationApi,
    private authAPI: AuthServicesApi,
    private router: Router
  ) {

    this.userPool = new CognitoUserPool({
      UserPoolId: USER_POOL_ID,
      ClientId: CLIENT_ID
    });
  }

  public setSignInStatus(isSignIn: boolean): void {
    this.isSignIn$.next(isSignIn);
  }

  public getAuthenticatedUser(): any {
    // let result = localStorage.getItem("userToken")
    let result = sessionStorage.getItem("userToken")

    return result;
  }

  public getAuthenticatedUserData(): CognitoUser {
    return this.userPool.getCurrentUser();
  }

  public getUserFirst(): String {
    var first;
    this.profileDetails$.subscribe(val => {
      first = val.firstName + ' ' + val.lastName
    });
    // var first = localStorage.getItem("first")
    return first;
  }

  public signIn(username: string, password: string): any {

    const authDetails = {
      Username: username,
      Password: password,
    };

    const loginApiResponse = this.authAPI.signInAPICall(authDetails);
    return loginApiResponse;
  }

  public changePassword(username: any, oldPassword: string, newPassword: string): any {


    const cognitoUser = {
      Username: username,
      Password: oldPassword,
      NewPassword: newPassword,
    };

    return this.authAPI.chnagePasswordAPICall(cognitoUser);
  }

  public setPassword(username: string, oldPassword: string, newPassword: string): any {

    const dataObject = {
      Username: username,
      Password: oldPassword,
      NewPassword: newPassword,
    };

    const setPasswordResponse = this.authAPI.setPasswordAPICall(dataObject);
    return setPasswordResponse;
  }

  public forgotPassword(username: any): any {

    const userData = {
      userName: username,
      forConfirm: false
    }

    return this.authAPI.forgetPasswordAPICall(userData);
  }

  public confirmVerificationCode(cognitoUser: CognitoUser, verificationCode: string, newPassword: string): any {
    const dataObj = {
      cognitoUser: cognitoUser,
      verificationCode: verificationCode,
      newPassword: newPassword,
      forConfirm: true
    }

    const verifyCodeApiResponse = this.authAPI.forgetPasswordAPICall(dataObj);;
    return verifyCodeApiResponse;

  }


  public logout(username: any): void {

    const userData = {
      Username: username
    }
    // localStorage.clear()
    this.authAPI.logoutApiCall(userData).subscribe((logoutResponse) => {

      if (logoutResponse == true) {

        // localStorage.clear()
        sessionStorage.clear()
        localStorage.clear();
        this.isSignIn$.next(false);
        this._profileDetails$ = undefined;
        this.router.navigateByUrl('/login').then(noop);
      }
    });
  }

  public signUp(company: string, firstName: string, lastName: string, email: string, password: string, accounttype: string, accountUUID: string = ''): Observable<any[]> {

    let registerDataObject = {
      email: email,
      password: password,
      company: company,
      firstName: firstName,
      lastName: lastName,
      accounttype: accounttype,
      accountUUID: accountUUID,
    }
    return this.registerAPI.registerAPICall(registerDataObject);

  };

  public confirmRegistration(username: any, code: any): any {

    const dataObj = {
      Username: username,
      code: code
    }

    return this.authAPI.registrationConfirmationAPICall(dataObj);
  }

}