import { Injectable } from '@angular/core';
import { Menu } from '@hconnect-ui';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable()
export class SidebarStore {
  private readonly innerMenu$ = new BehaviorSubject<Menu[]>(undefined);
  private readonly innerIsSidebarCollapsed$ = new BehaviorSubject<boolean>(false);

  get menu$(): Observable<Menu[]> {
    return this.innerMenu$.asObservable();
  }

  get isSidebarCollapsed$(): Observable<boolean> {
    return this.innerIsSidebarCollapsed$.asObservable();
  }

  public setMenu(menu: Menu[]): void {
    this.innerMenu$.next(menu);
  }

  public toggleSidebar(): void {
    this.innerIsSidebarCollapsed$.next(!this.innerIsSidebarCollapsed$.value);
  }
}
