import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';

import { Library } from '../models/library.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class LibraryApi extends ApiAbstraction<Library> {
  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  private readonly path = '/library';

  protected definePath(): string {
    return '/library';
  }

  public getMarketPlace(accountid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}`);
  }
}
