import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { AccountRole } from '../models/account-role.model';
import { AuthService } from '../services/auth.service';

@Injectable()
export class AdminGuard implements CanActivate {
  public constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<boolean | UrlTree> {
    return this.authService.profileDetails$.pipe(
      map(profileDetails =>
        profileDetails && profileDetails.role === AccountRole.ADMIN ? true : this.router.createUrlTree(['/not-found'])
      )
    );
  }
}
