import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { Application, NewApplication } from '../models/application.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class ApplicationApi extends ApiAbstraction<Application> {
  private readonly path = '/vendor/applications';

  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected definePath(): string {
    return '/vendor/applications';
  }

  public createApplication(applicationData: NewApplication): Observable<string> {  
    return this.httpClient.post<string>(`${this.path}`, applicationData);
  }

  public getUploadURL(): Observable<any> {
    return this.httpClient.get<string>(`${this.path}/getlogourl`)
  }

  public uploadLogo(url,fileData: any): Observable<any> {
    return this.httpClient.put<string>(`${this.path}/getlogourl`, fileData)
  }

  public deleteApplication(uuid: any): Observable<any> {
    return this.httpClient.put<string>(`${this.path}/delete/${uuid}`, null)
  }

  public generateAuth0(data: any): Observable<any> {
    return this.httpClient.post<string>(`${this.path}/auth0Generate`, data)
  }

  public generateLink(data: any): Observable<string> {
    return this.httpClient.post<string>(`${this.path}/generateLink`, data);
  }

  public getHotelApplications(uuid: string): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/${uuid}/customers`);
  }

  public getDeploymentData(systemuuid: any): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/deployment/${systemuuid}`);
  }
}