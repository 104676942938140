import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { PageSizeComponent } from './page-size.component';

@NgModule({
  declarations: [PageSizeComponent],
  imports: [CommonModule],
  exports: [PageSizeComponent]
})
export class PageSizeModule {}
