import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { Customer } from '../models/customer.model';
import { ApiAbstraction } from './api.abstraction';
import { Observable } from 'rxjs';

@Injectable()
export class CustomerApi extends ApiAbstraction<Customer> {
  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }
  private readonly path = '/vendor';
  protected definePath(): string {
    return '/vendor/customers';
  }

  public vendorCustomerList(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/vendorcustomerlist`);
  }
}
