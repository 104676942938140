import { ChangeDetectionStrategy, Component, Input, Output } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Component({
  selector: 'hcon-page-size',
  templateUrl: './page-size.component.html',
  styleUrls: ['./page-size.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PageSizeComponent {
  private readonly pageSize$ = new BehaviorSubject<number>(25);

  public readonly sizeOptions = [10, 25, 50, 100];

  public isOpenOptions = false;
  @Input() set pageSize(value: number) {
    this.pageSize$.next(value || 10);
  }
  get pageSize(): number {
    return this.pageSize$.value;
  }
  @Output() public pageSizeChange = this.pageSize$.asObservable();

  public changPageSize(event: Event, size: number): void {
    event.stopPropagation();
    this.isOpenOptions = false;
    if (this.pageSize$.value !== size) {
      this.pageSize$.next(size);
    }
  }

  public handleOpenOptions(isOpenOptions: boolean): void {
    this.isOpenOptions = isOpenOptions;
  }
}
