import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { VendorList } from '../models/vendor.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class VendorManagementApi extends ApiAbstraction<VendorList> {
  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }
  private readonly path = '/admin/vendor';

  protected definePath(): string {
    return '/admin/vendor';
  }

  /*
  public updateSettings(account: Account): Observable<unknown> {
    return this.httpClient.put<unknown>(`${this.path}/settings`, account);
  }
*/
  
}
