import { Injectable } from '@angular/core';
import { Observable, Subject } from 'rxjs';

@Injectable()
export class HeaderStore {
  private readonly innerLogout$ = new Subject<unknown>();

  get logout$(): Observable<unknown> {
    return this.innerLogout$.asObservable();
  }

  public logout(): void {
    this.innerLogout$.next(undefined);
  }
}
