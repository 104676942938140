import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';

import { JTableComponent } from './j-table.component';

@NgModule({
  declarations: [JTableComponent],
  imports: [CommonModule],
  exports: [JTableComponent]
})
export class JTableModule {}
