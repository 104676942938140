import { Menu } from '@hconnect-ui';

export const END_USER_MENU: Menu[] = [
  {
    name: 'HOME',
    fontAwesomeIconClassName: 'fas fa-tachometer-alt',
    isDisplayed: true,
    routerLink: '/dashboard' 
  },
  {
    name: 'AUTOMATE',
    fontAwesomeIconClassName: 'fas fa-route',
    isDisplayed: true,
    routerLink: '/automate'
  },
  {
    name: 'ESB',
    fontAwesomeIconClassName: 'fas fa-bezier-curve',
    isDisplayed: true,
    routerLink: '/esb'
  },

  {
    name: 'UNIVERSAL API',
    fontAwesomeIconClassName: 'fas fa-code-branch',
    isDisplayed: true,
    routerLink: '/universalapi'
  },
  {
    name: 'SYNC',
    fontAwesomeIconClassName: 'fas fa-exchange-alt',
    isDisplayed: true,
    routerLink: '/sync'
  },
  {
    name: 'OPERA CLOUD SYNC',
    fontAwesomeIconClassName: 'fab fa-opera',
    isDisplayed: true,
    routerLink: '/operasync'
  },
  {
    name: 'VAULT',
    fontAwesomeIconClassName: 'fas fa-treasure-chest',
    isDisplayed: true,
    routerLink: '/vault'
  },
  {
    name: 'Vendor Settings',
    fontAwesomeIconClassName: 'fas fa-server',
    isDisplayed: true,
    submenu: [
      /*
      {
        name: 'Customers',
        fontAwesomeIconClassName: 'fas fa-hotel',
        isDisplayed: false,
        routerLink: '/vendor/customers'
      },
      */
      {
        name: 'Applications',
        fontAwesomeIconClassName: 'fas fa-laptop',
        isDisplayed: true,
        routerLink: '/vendor/applications'
      }
    ]
  },
  {
    name: 'SETTINGS',
    fontAwesomeIconClassName: 'fas fa-id-card',
    isDisplayed: true,
    submenu: [
      {
        name: 'Profile',
        fontAwesomeIconClassName: 'fas fa-address-book',
        isDisplayed: true,
        routerLink: '/profile'
      },
     
      {
        name: 'Connections',
        fontAwesomeIconClassName: 'fas fa-hdd',
        isDisplayed: true,
        routerLink: '/systems'
      },
      {
        name: 'Vendor Access',
        fontAwesomeIconClassName: 'fas fa-building',
        isDisplayed: true,
        routerLink: '/vendors'
      },
      {
        name: 'Region Settings',
        fontAwesomeIconClassName: 'fas fa-globe',
        isDisplayed: true,
        routerLink: '/regionsettings'
      },
      /*
      {
        name: 'Billing',
        fontAwesomeIconClassName: 'fas fa-credit-card',
        isDisplayed: true,
        routerLink: '/billing'
      },
      */
      {
        name: 'Users',
        fontAwesomeIconClassName: 'fas fa-user-cog',
        isDisplayed: true,
        routerLink: '/users'
      },
      {
        name: 'Linked Accounts',
        fontAwesomeIconClassName: 'fas fa-link',
        isDisplayed: true,
         routerLink: '/linked-accounts'
      }
    ]
  }
];

export const ADMIN_MENU: Menu[] = [
  {
    name: 'Account Management',
    fontAwesomeIconClassName: 'fa fa-id-card',
    isDisplayed: true,
    routerLink: '/account-management'
  },
  {
    name: 'Connector Management',
    fontAwesomeIconClassName: 'fa fa-plug',
    isDisplayed: true,
    routerLink: '/connector-management'
  },
  {
    name: 'Vendor Management',
    fontAwesomeIconClassName: 'fa fa-handshake',
    isDisplayed: true,
    routerLink: '/vendor-management'
  },
  {
    name: 'System Library',
    fontAwesomeIconClassName: 'fas fa-books',
    isDisplayed: true,
    routerLink: '/system-library'
  },
  /*
  {
    name: 'Billing / Invoicing',
    fontAwesomeIconClassName: 'fas fa-file-invoice-dollar',
    isDisplayed: true,
    routerLink: '/invoice-management'
  },*/
];



// import { Menu } from '@hconnect-ui';

// export const END_USER_MENU: Menu[] = [
//   {
//     name: 'Home',
//     fontAwesomeIconClassName: 'fas fa-tachometer-alt',
//     isDisplayed: true,
//     routerLink: '/dashboard'
//   },
//   {
//     name: 'AUTOMATE',
//     fontAwesomeIconClassName: 'fas fa-route',
//     isDisplayed: true,
//     submenu: [
//       {
//         name: 'Connectors',
//         fontAwesomeIconClassName: 'fas fa-laptop-code',
//         isDisplayed: true,
//         routerLink: '/connectors'
//       }
//     ]
//   },
//   {
//     name: 'ESB',
//     fontAwesomeIconClassName: 'fas fa-bezier-curve',
//     isDisplayed: true,
//     submenu: [
//       {
//         name: 'Processes',
//         fontAwesomeIconClassName: 'fas fa-forward',
//         isDisplayed: true,
//         routerLink: '/esb'
//       },
//       {
//         name: 'Universal API',
//         fontAwesomeIconClassName: 'fas fa-code-branch',
//         isDisplayed: true,
//         routerLink: '/universalapi'
//       },
//       {
//         name: 'Connected Systems',
//         fontAwesomeIconClassName: 'fas fa-hdd',
//         isDisplayed: true,
//         routerLink: '/systems'
//       }
//     ]
//   },
//   {
//     name: 'SYNC',
//     fontAwesomeIconClassName: 'fas fa-exchange-alt',
//     isDisplayed: true,
//     submenu: [
//       {
//         name: 'Projects',
//         fontAwesomeIconClassName: 'fas fa-list',
//         isDisplayed: true,
//         routerLink: '/sync'
//       },
//       {
//         name: 'Vault',
//         fontAwesomeIconClassName: 'fas fa-treasure-chest',
//         isDisplayed: true,
//         routerLink: '/vault'
//       }
//     ]
//   },
//   {
//     name: 'Vendor Settings',
//     fontAwesomeIconClassName: 'fas fa-server',
//     isDisplayed: true,
//     submenu: [
//       {
//         name: 'Customers',
//         fontAwesomeIconClassName: 'fas fa-hotel',
//         isDisplayed: true,
//         routerLink: '/vendor/customers'
//       },
//       {
//         name: 'Applications',
//         fontAwesomeIconClassName: 'fas fa-laptop',
//         isDisplayed: true,
//         routerLink: '/vendor/applications'
//       }
//     ]
//   },
//   {
//     name: 'Account Details',
//     fontAwesomeIconClassName: 'fas fa-id-card',
//     isDisplayed: true,
//     submenu: [
//       {
//         name: 'Profile',
//         fontAwesomeIconClassName: 'fas fa-address-book',
//         isDisplayed: true,
//         routerLink: '/profile'
//       },
//       {
//         name: 'Settings',
//         fontAwesomeIconClassName: 'fas fa-cog',
//         isDisplayed: true,
//         routerLink: '/settings'
//       },
//       {
//         name: 'Billing',
//         fontAwesomeIconClassName: 'fas fa-credit-card',
//         isDisplayed: true,
//         routerLink: '/billing'
//       },
//       {
//         name: 'User Management',
//         fontAwesomeIconClassName: 'fas fa-user-cog',
//         isDisplayed: true,
//         routerLink: '/users'
//       }
//     ]
//   }
// ];

// export const ADMIN_MENU: Menu[] = [
//   {
//     name: 'Account Management',
//     fontAwesomeIconClassName: 'fa fa-id-card',
//     isDisplayed: true,
//     routerLink: '/account-management'
//   },
//   {
//     name: 'Connector Management',
//     fontAwesomeIconClassName: 'fa fa-plug',
//     isDisplayed: true,
//     routerLink: '/connector-management'
//   },
//   {
//     name: 'Vendor Management',
//     fontAwesomeIconClassName: 'fa fa-handshake',
//     isDisplayed: true,
//     routerLink: '/vendor-management'
//   },
//   {
//     name: 'System Library',
//     fontAwesomeIconClassName: 'fas fa-books',
//     isDisplayed: true,
//     routerLink: '/system-library'
//   },
//   /*
//   {
//     name: 'Billing / Invoicing',
//     fontAwesomeIconClassName: 'fas fa-file-invoice-dollar',
//     isDisplayed: true,
//     routerLink: '/invoice-management'
//   },*/
// ];
