import { HttpClient } from '@angular/common/http';
import { HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';
import { BillingList } from '../models/billing.model';
import { InvoiceList } from '../models/billing-invoices.model';

// import { VaultRecordList, VaultList } from '../models/vault.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class BillingApi extends ApiAbstraction<BillingList> {
  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected definePath(): string {
    return '/billing';
  }

  public getBillingList(stripeCustomerID): Observable<BillingList[]> {
    var path = '/billing/subscriptions/customer/' + stripeCustomerID;
    return this.httpClient.get<BillingList[]>(path);
    // var path = 'https://5rsx0psoq6.execute-api.ap-southeast-2.amazonaws.com/v1/billing/createsubscription'
    // return this.httpClient.get<BillingList[]>(path);
  }

  public makePayment(body): Observable<any[]> {
    var path = '/billing/createsubscription';
    return this.httpClient.post<any[]>(path, body);
  }
}
