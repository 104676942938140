import { ChangeDetectionStrategy, ChangeDetectorRef, Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { URL_STATUS_PAGE } from '@environments/environment';
import { AccountManagementApi } from '@core/api/account-management.api';
import { UserApi } from '@core/api/user.api';
import { LoadingService } from '@hconnect-ui';
import { Router } from '@angular/router';
import { noop } from 'rxjs';

@Component({
  selector: 'hcon-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class HeaderComponent implements OnInit {
  public readonly urlStatusPage = URL_STATUS_PAGE;

  public isOpenedSelectBox = false;
  public isHotelOpenedSelectBox = false;
  @Input() public userName: string;
  @Output() public toggleSidebar = new EventEmitter<unknown>();
  @Output() public logout = new EventEmitter<unknown>();

  hotellist: any;
  hotellistData: any;
  companyName: any;

  public constructor(private changeDetectorRef: ChangeDetectorRef,
    private accountManagementApi: AccountManagementApi,
    private loadingService: LoadingService,
    private router: Router,
    private userApi: UserApi) { }

  async ngOnInit() {
    this.hotellist = await this.accountManagementApi.gethotellist();
    this.hotellist.subscribe(data => {
      this.hotellistData = data;
      let x = this.userApi.getAccounts();
      x.subscribe(result => {
        this.companyName = result[0].companyname
        this.changeDetectorRef.detectChanges();
      })
      this.changeDetectorRef.detectChanges();
    })

  }

  async onHotelChange(event) {
    let accountId = event[0];
    for (let i = 0; i < this.hotellistData.length; i++) {
      if (this.hotellistData[i].accountuuid == accountId) {
        this.loadingService.show('Saving');
        this.accountManagementApi.onHotelChange(accountId).subscribe(data => {
          this.loadingService.hide();
          // this.ngOnInit();
          this.router.navigateByUrl('/dashboard').then(() => {
            window.location.reload();
          });



        })
      }
    }

  }

  public onClickBurger(): void {
    this.toggleSidebar.emit();
  }

  public onLogout(): void {
    // localStorage.clear();
    this.logout.emit();
  }

  public changeStateSelectBox(isOpenedSelectBox: boolean): void {
    this.isOpenedSelectBox = isOpenedSelectBox;
    this.changeDetectorRef.detectChanges();
  }

  public changeHotelSelectBox(isHotelOpenedSelectBox: boolean): void {
    this.isHotelOpenedSelectBox = isHotelOpenedSelectBox;
    this.changeDetectorRef.detectChanges();
  }
}
