import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { SyncTemplate } from '../models/sync-template.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class SyncTemplateApi extends ApiAbstraction<SyncTemplate> {
  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }
  private readonly path = '/sync';

  protected definePath(): string {
    return '/sync/templates';
  }
  public getSyncTemplate(uuid: string): Observable<SyncTemplate[]> {
    return this.httpClient.get<SyncTemplate[]>(`${this.path}/templates/${uuid}`);
  }
  public getSyncTemplates(): Observable<SyncTemplate[]> {
    return this.httpClient.get<SyncTemplate[]>(`${this.path}/templates`);
  }
}