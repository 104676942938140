import {
  ChangeDetectionStrategy,
  Component,
  ElementRef,
  EventEmitter,
  Input,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import { Menu } from '@hconnect-ui';
import { fromEvent, Observable } from 'rxjs';
import { map } from 'rxjs/operators';

@Component({
  selector: 'app-private-layout',
  templateUrl: './private-layout.component.html',
  styleUrls: ['./private-layout.component.sass'],
  changeDetection: ChangeDetectionStrategy.OnPush
})
export class PrivateLayoutComponent implements OnInit {
  @ViewChild('rightContainer', { static: true }) private rightContainer: ElementRef<HTMLElement>;

  @Input() public userName: string;
  @Input() public menu: Menu[];
  @Output() public logout = new EventEmitter<unknown>();
  public isCollapsed = false;
  public isContentScrollDown$: Observable<boolean>;

  public ngOnInit(): void {
        this.isContentScrollDown$ = fromEvent(this.rightContainer.nativeElement, 'scroll').pipe(
      map(() => this.rightContainer.nativeElement.scrollTop > 50)
    );
  }

  public toggleSidebar(): void {
    this.isCollapsed = !this.isCollapsed;
  }

  public onLogout(): void {
    this.logout.emit();
  }
}
