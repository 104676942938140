import { ChangeDetectionStrategy, Component, OnInit, HostListener, OnDestroy } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
// import { AuthService } from '@core/services/auth.service';
import { AuthService } from './core/services/auth.service';
import { LoadingService } from '@hconnect-ui';
import { adjustSidebarMenu } from '@shared/utils/sidebar.util';
import { HeaderStore } from '@stores/header.store';
import { SidebarStore } from '@stores/sidebar.store';
import { noop } from 'rxjs';
import { filter, finalize, map, switchMap, tap } from 'rxjs/operators';
import { Idle, DEFAULT_INTERRUPTSOURCES } from '@ng-idle/core';
import { Keepalive } from '@ng-idle/keepalive';
import { SessiontimeoutService } from '../app/sessiontimeout.service';
declare const gtag: Function;
@Component({
  selector: 'app-root',
  template: `
    <router-outlet></router-outlet>
    <hcon-loading-indicator></hcon-loading-indicator>
  ` //,
  //changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppComponent implements OnInit {

  idleState = 'Not started.';
  timedOut = false;
  lastPing?: Date = undefined;
  title = 'angular-idle-timeout';
  public constructor(
    private router: Router,
    private authService: AuthService,
    private loadingService: LoadingService,
    private sidebarStore: SidebarStore,
    private headerStore: HeaderStore,
    private idle: Idle,
    private keepalive: Keepalive,
    private sessiontimeoutService: SessiontimeoutService
  ) {

    // idle.setIdle(20); 
    idle.setIdle(80000);   // idle screen wait upto given time 80000
    idle.setTimeout(20);  // set timeout waring upto  20 second

    idle.setInterrupts(DEFAULT_INTERRUPTSOURCES);

    idle.onIdleEnd.subscribe(() => {
      this.idleState = 'No longer idle.';
      this.reset();
    });

    idle.onTimeout.subscribe(() => {
      this.idleState = 'Timed out!';
      this.timedOut = true;
      this.logout();
      // sessionStorage.clear();
      // localStorage.clear();
      // this.router.navigateByUrl('/login').then(noop);
    });
    idle.onTimeoutWarning.subscribe(countdown => {
      this.idleState = 'You will time out in ' + countdown + ' seconds!';
    });

    idle.onIdleStart.subscribe(() => {
      this.idleState = "You've gone idle!";
      // this.childModal.show();
    });

    // sets the ping interval to 15 seconds
    keepalive.interval(15);
    keepalive.onPing.subscribe(() => {
      this.lastPing = new Date()
      if (this.lastPing) {
        this.sessiontimeoutService.resetSessionTimeout();
      }

    }

    );

    this.reset();

    this.router.events.pipe(filter(event => event instanceof NavigationEnd)).subscribe((event: NavigationEnd) => {
      gtag('event', 'page_view', {
        page_path: event.urlAfterRedirects
      });
    });
  }

  public ngOnInit(): void {
    this.setupUIData();
  }



  @HostListener('window:beforeunload')
  onBeforeUnload(): void {
    window.addEventListener('onunload', () => {
      localStorage.clear();
      sessionStorage.clear();
    });
    this.sessiontimeoutService.resetSessionTimeout(); // Reset the session timeout when the user closes the browser
  }



  private setupUIData(): void {

    this.headerStore.logout$.subscribe(() => this.logout());
    this.authService.signInStatus$
      .pipe(
        filter(Boolean),
        tap(() => this.loadingService.show(' ')),
        switchMap(() =>
          this.authService.profileDetails$.pipe(
            map(adjustSidebarMenu),
            finalize(() => this.loadingService.hide())
          )
        )
      )
      .subscribe(adjustedMenu => this.sidebarStore.setMenu(adjustedMenu));
    // let getAuthResponse = this.authService.getAuthenticatedUser();

    if (this.authService.getAuthenticatedUser()) {

      this.authService.setSignInStatus(true);
    }
  }

  logout(): void {
    let username = sessionStorage.getItem("userName");
    this.authService.logout(username);
    localStorage.clear()
    sessionStorage.clear();
    // this.keepalive.onPing.unsubscribe();
    this.router.navigateByUrl('/login').then(noop);
  }
  reset() {

    this.idle.watch();
    this.idleState = 'Started.';
    this.timedOut = false;
    this.sessiontimeoutService.resetSessionTimeout();
  }


}
