import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { Account } from '../models/account-management.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class AccountManagementApi extends ApiAbstraction<Account> {
  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }
  private readonly path = '/account';

  protected definePath(): string {
    return '/admin/accounts';
  }

  public updateSettings(account: Account): Observable<unknown> {
    return this.httpClient.put<unknown>(`${this.path}/settings`, account);
  }

  public gethotellist(): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/hotels/hotellist`);
  }
  public onHotelChange(accountId): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/hotels/hotellist/${accountId}`,'');
  }

}
