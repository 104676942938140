import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { ProcessRename } from '../models/process-rename.model';
import { Process } from '../models/process.model';
import { StepProcess } from '../models/step-process.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class EsbApi extends ApiAbstraction<Process> {
  private readonly path = '/esb';

  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }

  protected definePath(): string {
    return '/esb';
  }

  public updateProcessStatus(actionid: number, isActive: boolean): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/status`, { actionid, isactive: isActive });
  }

  public getPropertiesStepProcess(processId: string, stepId: string): Observable<StepProcess[]> {
        
    return this.httpClient.get<StepProcess[]>(`${this.path}/${processId}/properties/${stepId}`);
  }

  public savePropertiesStepProcess(processId: string, stepId: string, body: unknown): Observable<string> {
        
    return this.httpClient.post<string>(`${this.path}/${processId}/properties/${stepId}`, body);
  }

  public renameProcess(processId: string, processName: string): Observable<string> {
    return this.httpClient
      .post<ProcessRename[]>(this.path, { processName, processId })
      .pipe(map(processes => processes && processes[0] && processes[0].id));
  }

  public saveFlowProcess(processId: string, flowProcess: unknown): Observable<unknown> {
    return this.httpClient.post<string>(`${this.path}/${processId}`, flowProcess);
  }

  public getProcessScheduleTime(actionUuid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/${actionUuid}/scheduletime`);
  }
  
  public esbProcessrun(actionUuid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/${actionUuid}/processrun`);
  }
}
