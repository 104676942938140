import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';

import { VaultRecordList, VaultList } from '../models/vault.model';
import { ApiAbstraction } from './api.abstraction';

@Injectable()
export class VaultApi extends ApiAbstraction<VaultList> {
  public constructor(protected httpClient: HttpClient) {
    super(httpClient);
  }
  private readonly path = '/vault';

  protected definePath(): string {
    return '/vault';
  }

  public getVaultList(): Observable<VaultList[]> {
    return this.httpClient.get<VaultList[]>(this.path);
  }

  public getVault(vaultId: string): Observable<VaultList> {
    var path = '/vault/' + vaultId;
    return this.httpClient.get<VaultList>(path);
  }

  public getRecord(vaultId: string, recordId: string): Observable<VaultList[]> {
    var path = '/vault/' + vaultId + '/record/' + recordId;
    return this.httpClient.get<VaultList[]>(path);
  }

  public searchRecords(vaultId: string, searchTerm: string, recordTypes: string): Observable<VaultRecordList[]> {
    var path = '/vault/' + vaultId + '?searchTerm=' + searchTerm;
    if (recordTypes != '' && recordTypes != undefined) {
      path += '&searchFilter=' + recordTypes;
    }
    return this.httpClient.get<VaultRecordList[]>(path);
  }

  public getVaultRecord(vaultid: number): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/record/${vaultid}`);
  }

  public vaultData(vaultuuid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/vaultdetail/${vaultuuid}`); /// select one
  } 
  public updateVault(data): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/vaultdetail`,data); // update one
  }

  public publishConnector(vaultId, recordId): Observable<any[]> {
    
    return this.httpClient.put<any[]>(`${this.path}/publish/${vaultId}/record/${recordId}`,'');
  }

  public updateVaultImage(data): Observable<any[]> {
    return this.httpClient.put<any[]>(`${this.path}/vaultdetailimage`,data);
  }
  public getVaultGdpr(vaultuuid): Observable<any[]> {
    return this.httpClient.get<any[]>(`${this.path}/vaultgdprsetting/${vaultuuid}`); // gdp one 
  } 
  
}
