import { Injectable } from '@angular/core';
import {
  ActivatedRoute,
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree
} from '@angular/router';
import { Observable, of } from 'rxjs';
import { map, switchMap } from 'rxjs/operators';

import { AuthService } from '../services/auth.service';

@Injectable()
export class AuthGuard implements CanActivate {
  constructor(private authService: AuthService, private route: ActivatedRoute, private router: Router) {}

  public canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot): Observable<UrlTree | boolean> {
    return this.authService.signInStatus$.pipe(
      switchMap(isSignIn =>
        isSignIn
          ? this.authService.profileDetails$.pipe(
              map(profileDetails => (!!profileDetails ? true : this.router.createUrlTree(['/login'])))
            )
          : of(this.router.createUrlTree(['/login']))
      )
    );
  }
}
